import React from "react"
import { Box, Flex, Spacer, Icon } from "@chakra-ui/react"
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaTwitterSquare } from "@react-icons/all-files/fa/FaTwitterSquare"
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaPhone } from "@react-icons/all-files/fa/FaPhone"
import { Link } from "gatsby-plugin-react-i18next"
import { styled } from "styled-components"
// import MenuToggle from "./menu-toggle"

const StyledLink = styled(Link)`
  margin-right: 0.8rem;
  font-size: 0.8rem;
  display: inline;
`
const SocialLink = styled(Link)`
  display: inline;
  margin-right: 0.8rem;
  font-size: 1.2rem;
  position: relative;
  top: 5px;
`
const IconLink = styled(Icon)`
  display: inline;
  position: relative;
  top: 2px;
  margin-right: 0.2rem;
`

const ContactBar = () => {
  return (
    <Box px="4" py="1" bg="#81b1ce" color="#0f1056">
      <Flex>
        <Box style={{ marginLeft: "7.7rem" }}>
          <Flex>
            <StyledLink href="mailto:ibrahim@peakfreights.com">
              <IconLink as={FaEnvelope} /> ibrahim@peakfreights.com
            </StyledLink>
            <StyledLink href="tel:+97450636034">
              <IconLink as={FaPhone} /> +974-50636034
            </StyledLink>
            <StyledLink href="tel:+97455882735">
              <IconLink as={FaPhone} /> +974-55882735
            </StyledLink>
          </Flex>
        </Box>
        <Spacer />
        <Box style={{ marginTop: "-5px" }}>
          <StyledLink href="/contact">Contact</StyledLink>
          <StyledLink href="/career">Career</StyledLink>
          <StyledLink href="/news">News &amp; Media</StyledLink>
          <SocialLink href="/">
            <Icon as={FaFacebookSquare} />
          </SocialLink>
          <SocialLink href="/">
            <Icon as={FaTwitterSquare} />
          </SocialLink>
          <SocialLink href="/">
            <Icon as={FaInstagramSquare} />
          </SocialLink>
          <SocialLink href="/">
            <Icon as={FaLinkedin} />
          </SocialLink>
        </Box>
      </Flex>
    </Box>
  )
}

export default ContactBar
