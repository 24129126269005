const React = require("react")
const { ChakraProvider } = require("@chakra-ui/react")

const { default: Layout } = require("./src/components/layout")
const { default: customTheme } = require("./src/theme/CustomTheme")
require("./i18n")

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => (
  <ChakraProvider theme={customTheme}>
    <Layout {...props}>{element}</Layout>
  </ChakraProvider>
)
