import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    // have a common namespace used around the full app
    resources: {
      en: {
        translations: require('./src/locales/en/translations.json')
      },
      id: {
        translations: require('./src/locales/id/translations.json')
      },
      jw: {
        translations: require('./src/locales/jw/translations.json')
      }
    },
    ns: ["translations"],
    defaultNS: "translations",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      useSuspense: false,
    },
  })

export default i18n