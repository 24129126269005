import React from "react"
import { Link, Text, Icon, Show } from "@chakra-ui/react"
import { Link as TLink } from "gatsby-plugin-react-i18next"

const MenuItem = ({ children, isLast, to = "/", icon, ...rest }) => {
  return (
    <Link to={to} as={TLink}>
      <Show below="sm">
        <Icon as={icon} />
      </Show>
      <Text as="span" {...rest} textDecoration="none">
        {children}
      </Text>
    </Link>
  )
}

export default MenuItem
